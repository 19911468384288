import React from "react"
import { twMerge } from "tailwind-merge"

const EndorsementsSection = ({ practice, theme, backgroundColor, color, className, titleClassName }) => {
  backgroundColor ||= theme.colorBgLight
  color ||= theme.colorTextDark

  const skills = practice.skills
    .map((skill) => ({
      name: skill.specialty?.name,
      position: skill.position
    }))
    .sort((a, b) => a.position - b.position)

  if (skills.length === 0) return null

  return (
    <section
      id="endorsements"
      className={twMerge("mt-[-1px] pb-24 pt-20 md:px-5 md:pb-16", className)}
      style={{ backgroundColor, color, fontFamily: theme.fontHeading }}>
      <div className="container mx-auto">
        <div className={twMerge("mb-10 text-center text-2xl font-bold", titleClassName)}>I help with</div>
        <div className="grid grid-cols-2 gap-4 min_md:mx-48 min_md:grid-cols-3">
          {skills.map((skill, index) => (
            <div key={index}>{skill.name}</div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default EndorsementsSection
