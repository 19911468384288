import { WebAppBookNow } from "@heal-me/healme-widget"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid"
import React, { useState } from "react"
import { twMerge } from "tailwind-merge"

import { practiceDefaultServiceId } from "../../@core/practice/practice.utils"
import { scrollToId } from "../ProProfile/ScrollToSection"

import AnimatedSection from "./AnimatedSection"

const Navigation = ({ practice, theme, className, color, viewingInBuilder, ClipPath, ClipPathTwo }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  color ||= theme.colorTextLight

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const logoUrl = practice.imageAssets.find((asset) => asset.description === "logo")?.resizedImageUrl

  return (
    <AnimatedSection
      style={{ color }}
      className={twMerge(
        "absolute top-0 z-10 flex w-full items-center justify-between px-12 py-6 delay-[250ms] md:p-4",
        className
      )}>
      <div className="text-left">
        {logoUrl ? (
          <img
            src={logoUrl}
            alt={`${practice.user?.name || "Practice"} logo`}
            className="max-h-32 max-w-[360px] md:max-w-[75%]"
          />
        ) : (
          <div
            className="text-[27px] md:text-lg"
            style={{ fontFamily: theme.fontHeading, fontWeight: theme.fontHeadingWeight }}>
            {practice.user.name}
          </div>
        )}
      </div>
      <div className="flex gap-8 md:hidden">
        <button
          className="border-b border-transparent transition duration-150 ease-out hover:border-[var(--hover-color)] active:border-b-2"
          style={{ "--hover-color": theme.colorAccent }}
          onClick={(e) => scrollToId(e, "about")}>
          About
        </button>
        <button
          className="border-b border-transparent transition duration-150 ease-out hover:border-[var(--hover-color)] active:border-b-2"
          style={{ "--hover-color": theme.colorAccent }}
          onClick={(e) => scrollToId(e, "services")}>
          Services
        </button>
        <button
          className="border-b border-transparent transition duration-150 ease-out hover:border-[var(--hover-color)] active:border-b-2"
          style={{ "--hover-color": theme.colorAccent }}
          onClick={(e) => scrollToId(e, "reviews")}>
          Reviews
        </button>
        <button
          className="border-b border-transparent transition duration-150 ease-out hover:border-[var(--hover-color)] active:border-b-2"
          style={{ "--hover-color": theme.colorAccent }}
          onClick={(e) => scrollToId(e, "about")}>
          Credentials
        </button>
        <WebAppBookNow
          practiceId={practice.id}
          serviceId={practiceDefaultServiceId(practice)}
          buttonCopy="Book Now"
          buttonClasses={twMerge(
            "border bg-transparent px-8 py-2 transition duration-150 ease-out hover:border-[var(--hover-color)] hover:bg-[var(--hover-color)]",
            theme.buttonStyle === "rounded" ? "rounded-full" : "rounded",
            viewingInBuilder ? "pointer-events-none" : ""
          )}
          style={{ "--hover-color": theme.colorAccent, borderColor: color }}
        />
      </div>
      {/* Mobile Navigation */}
      <div className="hidden md:block">
        <button onClick={toggleMenu}>
          <Bars3Icon className="h-6 w-6" />
        </button>
        <div
          style={{ backgroundColor: theme.colorBgLight, color: theme.colorTextDark }}
          className={`fixed right-0 top-0 z-50 flex h-screen w-full flex-col items-center justify-center gap-8 overflow-hidden transition-transform duration-300 ease-in-out md:px-5 ${
            isMenuOpen ? "translate-x-0" : "translate-x-full"
          }`}>
          <div className="absolute right-4 top-4 z-10">
            <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={toggleMenu} />
          </div>
          <div>
            {practice.logo ? (
              <img
                src={practice.logo}
                alt={`${practice.user?.name || "Practice"} logo`}
                className="max-h-12 max-w-[180px]"
              />
            ) : (
              <div className="text-lg font-semibold">{practice.user.name}</div>
            )}
            <div className="mt-2 text-sm" style={{ color: theme.colorGrey }}>
              {practice.user.title}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-4">
            <button
              onClick={(e) => {
                scrollToId(e, "about")
                toggleMenu()
              }}>
              About
            </button>
            <button
              onClick={(e) => {
                scrollToId(e, "services")
                toggleMenu()
              }}>
              Services
            </button>
            <button
              onClick={(e) => {
                scrollToId(e, "reviews")
                toggleMenu()
              }}>
              Reviews
            </button>
            <button
              onClick={(e) => {
                scrollToId(e, "about")
                toggleMenu()
              }}>
              Credentials
            </button>
          </div>
          <div className="w-full">
            <WebAppBookNow
              practiceId={practice.id}
              serviceId={practice.defaultServiceId}
              buttonCopy="Book Now"
              buttonClasses={twMerge(
                "w-full border px-8 py-2",
                theme.buttonStyle === "rounded" ? "rounded-full" : "rounded",
                viewingInBuilder ? "pointer-events-none" : ""
              )}
              style={{
                color: theme.colorTextLight,
                backgroundColor: theme.colorAccent
              }}
            />
          </div>
          {ClipPath && <ClipPath />}
          {ClipPathTwo && <ClipPathTwo />}
        </div>
      </div>
    </AnimatedSection>
  )
}

export default Navigation
