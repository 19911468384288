import React from "react"

import { useCurrentUser, CurrentUserProvider } from "../../contexts/CurrentUserContext"
import { useToast } from "../../contexts/ToastContext"
import { ToastProvider } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"
import { OPACITY_25, OPACITY_75 } from "../../utils/utils"
import AboutSection from "../ProProfileShared/AboutSection"
import BottomCTA from "../ProProfileShared/BottomCTA"
import ChatWidget from "../ProProfileShared/ChatWidget"
import EndorsementsSection from "../ProProfileShared/EndorsementsSection"
import Footer from "../ProProfileShared/Footer"
import GallerySection from "../ProProfileShared/GallerySection"
import HeroSection from "../ProProfileShared/HeroSection"
import Map from "../ProProfileShared/Map"
import Navigation from "../ProProfileShared/Navigation"
import Quote from "../ProProfileShared/Quote"
import ReviewsSection from "../ProProfileShared/ReviewsSectionVariationOne"
import ServicesSection from "../ProProfileShared/ServicesSection"
import TopReviewsSection from "../ProProfileShared/TopReviewsSection"

import TriangleDivider from "./TriangleDivider"
import ValueStatementSection from "./ValueStatementSection"

const ProProfileThemeOne = ({ practice, highlight, styles, viewingInBuilder }) => {
  const currentUser = useCurrentUser()
  const toast = useToast()
  const theme = practice.theme
  const primaryColor = theme.colorPrimary
  const secondaryColor = theme.colorSecondary
  const isMobile = window.innerWidth < 768

  return (
    <CurrentUserProvider>
      <GraphQLProvider>
        <ToastProvider>
          <main style={styles} className="md:overflow-x-hidden">
            <HeroSection
              theme={theme}
              practice={practice}
              highlight={highlight}
              viewingInBuilder={viewingInBuilder}
              Navigation={() => <Navigation practice={practice} theme={theme} viewingInBuilder={viewingInBuilder} />}
              ClipPath={() => (
                <div
                  className="absolute bottom-[-2px] h-6 w-full clip-path-triangle-top"
                  style={{ backgroundColor: primaryColor }}
                />
              )}
            />
            <TopReviewsSection practice={practice} className="pb-0" theme={theme} />
            <TriangleDivider
              topBackgroundColor={primaryColor}
              bottomBackgroundColor={theme.colorBgLight}
              middleBackgroundColor={primaryColor + OPACITY_75}
            />
            <ServicesSection
              practice={practice}
              theme={theme}
              includeImage
              viewingInBuilder={viewingInBuilder}
              titleClassName="mt-10 min_md:mt-32 text-left md:text-center"
              className="pt-12 md:pb-20 md:pt-0"
              imageClassName="absolute bottom-0 left-0 h-auto"
              mobileImageClassName="px-0 mb-0"
              animateTitle={true}
            />
            <TriangleDivider
              topBackgroundColor={theme.colorBgLight}
              bottomBackgroundColor={secondaryColor}
              middleBackgroundColor={secondaryColor + OPACITY_25}
            />
            <ValueStatementSection
              practice={practice}
              theme={theme}
              highlight={highlight}
              viewingInBuilder={viewingInBuilder}
            />
            <TriangleDivider
              topBackgroundColor={secondaryColor}
              bottomBackgroundColor={theme.colorBgLight}
              middleBackgroundColor={secondaryColor + OPACITY_75}
            />
            <AboutSection
              practice={practice}
              theme={theme}
              highlight={highlight}
              cardBackgroundColor={theme.colorWhite}
              credentialsBackgroundColor={theme.colorBgLight}
              viewingInBuilder={viewingInBuilder}
            />
            <TriangleDivider
              topBackgroundColor={theme.colorBgLight}
              bottomBackgroundColor={theme.colorPrimaryDark}
              middleBackgroundColor={theme.colorPrimaryDark + OPACITY_25}
            />
            <ReviewsSection
              practice={practice}
              theme={theme}
              backgroundColor={theme.colorPrimaryDark}
              color={theme.colorTextLight}
              starsClassName="justify-start md:justify-center"
            />
            <EndorsementsSection
              practice={practice}
              theme={theme}
              backgroundColor={theme.colorPrimaryDark}
              color={theme.colorTextLight}
            />
            <TriangleDivider
              topBackgroundColor={theme.colorPrimaryDark}
              bottomBackgroundColor={theme.colorBgLight}
              middleBackgroundColor={theme.colorPrimaryDark + OPACITY_75}
            />
            <GallerySection
              practice={practice}
              theme={theme}
              highlight={highlight === "Gallery photos"}
              style={{ backgroundColor: theme.colorBgLight }}
              containerClassName="gap-16 max-w-[800px] mx-auto md:gap-4"
              imageClassName="h-[380px] w-[360px] md:w-full md:h-auto"
              maxHeight={isMobile ? "800px" : "824px"}
            />
            <TriangleDivider
              topBackgroundColor={theme.colorBgLight}
              bottomBackgroundColor={secondaryColor}
              middleBackgroundColor={secondaryColor + OPACITY_25}
            />
            <Quote
              theme={theme}
              text={practice.quote}
              author={practice.quoteAuthor}
              id="quote"
              highlight={highlight === "quote"}
              backgroundColor={secondaryColor}
            />
            <BottomCTA
              practice={practice}
              theme={theme}
              highlight={highlight}
              viewingInBuilder={viewingInBuilder}
              ClipPath={() => (
                <div
                  className="absolute top-[-1px] h-6 w-full clip-path-triangle-bottom"
                  style={{ backgroundColor: secondaryColor }}
                />
              )}
              ClipPathTwo={() => (
                <div
                  className="absolute bottom-[-2px] h-6 w-full clip-path-triangle-top"
                  style={{ backgroundColor: secondaryColor }}
                />
              )}
            />
            <div className="h-10 w-full" style={{ backgroundColor: secondaryColor }}></div>
            <Map
              practice={practice}
              theme={theme}
              ClipPath={() => (
                <div
                  className="absolute top-[-1px] h-6 w-full clip-path-triangle-bottom"
                  style={{ backgroundColor: secondaryColor }}
                />
              )}
              ClipPathTwo={() => (
                <div
                  className="absolute bottom-[-1px] h-6 w-full clip-path-triangle-top"
                  style={{ backgroundColor: secondaryColor }}
                />
              )}
            />
            <Footer
              practice={practice}
              theme={theme}
              backgroundColor={secondaryColor}
              viewingInBuilder={viewingInBuilder}
            />
            <ChatWidget practice={practice} currentUser={currentUser} toast={toast} />
          </main>
        </ToastProvider>
      </GraphQLProvider>
    </CurrentUserProvider>
  )
}

export default ProProfileThemeOne
