import { gql } from "urql"

export const REMOVE_IMAGE_ASSET_MUTATION = gql`
  mutation RemoveImageAsset($description: String!) {
    removeImageAsset(description: $description) {
      result
      errors
    }
  }
`
